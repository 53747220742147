<template lang="pug">
.page
  el-card
    el-tabs
      el-tab-pane(label="修改资料") 
        el-form(label-width="6em")
          el-form-item(label="姓名")
            el-input(v-model="userExtendInfo.trueName" style="width:200px")
          el-form-item(label="头像")
            jj-imagePick(v-model="userExtendInfo.avatar")
          el-form-item()
            el-button(type="primary" @click="handleSubmitEditProfile") 保存
      el-tab-pane(label="修改密码") 
        el-form(label-width="6em")
          el-form-item(label="原密码")
            el-input(v-model="editPasswordFormData.oldPassword" type="password" style="width:200px")
          el-form-item(label="新密码")
            el-input(v-model="editPasswordFormData.newPassword" type="password" style="width:200px")
          el-form-item(label="重复新密码")
            el-input(v-model="editPasswordFormData.newPassword2" type="password" style="width:200px")
          el-form-item()
            el-button(type="primary" @click="handleSubmitEditPassword") 保存

</template>

<script setup>
import { inject, onMounted, ref } from 'vue'
import { userInfo, handleLogoutByExpire } from '@/provider/account'
const { fetch, message, router } = inject('global')

const userExtendInfo = ref({
  trueName: '',
  avatar: ''
})
const editPasswordFormData = ref({
  oldPassword: '',
  newPassword: '',
  newPassword2: ''
})

function handleSubmitEditProfile() {
  fetch
    .put(`/account/${userInfo.value.id}`, {
      extendInfo: {
        avatar: userExtendInfo.value.avatar,
        trueName: userExtendInfo.value.trueName
      }
    })
    .then(res => {
      message.success('修改成功')
      router.push('/')
    })
    .catch(err => {
      message.error(err?.msg || err)
    })
}
function handleSubmitEditPassword() {
  fetch
    .put(`/account/password`, editPasswordFormData.value)
    .then(res => {
      message.success('修改成功')
      handleLogoutByExpire()
    })
    .catch(err => {
      console.log('>>>>>>> err', err)
      message.error(err?.msg || err)
    })
}

onMounted(() => {
  console.log('>>>>>>> userInfo.value', userInfo.value)
  userExtendInfo.value.trueName = userInfo.value.trueName
  userExtendInfo.value.avatar = userInfo.value.avatar
})
</script>

<style lang="less" scoped>
.page {
  width: 100%;
}
</style>
